import React from 'react';
import { DashboardDetails } from '../../Containers/CreateDashboard/CreateDashboard.types';
import { ParameterDetails } from '../../Services/Embeddedbi.types';
import ParameterFields from './ParameterFields';

interface DashboardParameterProps {
	parameterDetails: ParameterDetails[];
	updateDashboardDetails: React.Dispatch<React.SetStateAction<DashboardDetails>>;
}

export default function DashboardParameters({ parameterDetails, updateDashboardDetails }: DashboardParameterProps) {
	function addParameter() {
		const newParameter = {
			quicksightParameter: '',
			stringValue: '',
		};
		let tempParameter = [...parameterDetails];
		tempParameter.push(newParameter as ParameterDetails);
		updateDashboardDetails(prevState => ({ ...prevState, parameters: tempParameter }))
	}
	
	function setParameter(modifiedParameter: ParameterDetails, index: number) {
		let tempParameters = [...parameterDetails];
		tempParameters[index] = modifiedParameter;
		updateDashboardDetails(prevState => ({ ...prevState, parameters: tempParameters }));
	}
	
	function removeParameter(index: number) {
		let existingParameters = [...parameterDetails];
		existingParameters = existingParameters.filter((_parameter, parameterIndex) => parameterIndex !== index);
		updateDashboardDetails(prevState => ({ ...prevState, parameters: existingParameters }));
	}
	
	return (
		<>
			<div className="flex space-between">
				<h4 className="mb-8">Hidden Parameters</h4>
				<div className="textOnlyButton" onClick={addParameter} data-testid="dashboard-add-parameters">+ add</div>
			</div>
			{parameterDetails &&
				<div className="p-16 mb-16 background-f9f9f9 createDashboard__filters">
					{parameterDetails.map((parameter: ParameterDetails, index: number) => (
						<ParameterFields key={index} parameterDetails={parameter} setParameterFields={(parameter: ParameterDetails) => setParameter(parameter, index)} removeParameter={() => removeParameter(index)}/>
					))}
				</div>
			}
		</>
	);
};