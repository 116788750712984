import React from 'react';
import StringFilterFields from './StringFilterFields';
import DateRangeFilterFields from './DateRangeFilterFields';
import BaseFilterFields from './BaseFilterFields';
import CustomSelectFilterFields from './CustomSelectFilterFields';

import { FilterDetails, StringFilterDetails, DataSelectFilterDetails, DateRangeFilterDetails, CustomSelectFilterDetails } from '../../Services/Embeddedbi.types';
import DataSelectFilterFields from './DataSelectFilterFields';

interface FilterProps {
	filterDetails: FilterDetails;
	setFilter: (newFilterDetails: FilterDetails) => void;
	removeFilter: () => void;
}

export default function Filter({ filterDetails, setFilter, removeFilter }: FilterProps) {
	return (
		<div data-testid="dashboard-filter-group">
			<div className="flex flex-end">
				<div className="textOnlyButton" onClick={removeFilter} data-testid="dashboard-filter-group-remove">x</div>
			</div>
			<BaseFilterFields filterDetails={filterDetails} setFilterFields={(newDateRangeFilterDetails: FilterDetails) => setFilter(newDateRangeFilterDetails)} />
			{filterDetails.type === 'string' && <StringFilterFields stringFilterDetails={filterDetails as StringFilterDetails} setFilterFields={(newFilterDetails: FilterDetails) => setFilter(newFilterDetails)} />}
			{filterDetails.type === 'dateRange' && <DateRangeFilterFields dateRangeFilterDetails={filterDetails as DateRangeFilterDetails} setFilterFields={(newFilterDetails: FilterDetails) => setFilter(newFilterDetails)} />}
			{filterDetails.type === 'dataSelect' && <DataSelectFilterFields dataSelectFilterDetails={filterDetails as DataSelectFilterDetails} setFilterFields={(newFilterDetails: FilterDetails) => setFilter(newFilterDetails)} />}
			{filterDetails.type === 'customSelect' && <CustomSelectFilterFields dataSelectFilterDetails={filterDetails as CustomSelectFilterDetails} setFilterFields={(newFilterDetails: FilterDetails) => setFilter(newFilterDetails)} />}
		</div>
	);
};