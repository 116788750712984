import React from 'react';

import { FilterDetails, StringFilterDetails } from '../../../Services/Embeddedbi.types';

interface StringFilterFieldsProps {
	stringFilterDetails: StringFilterDetails,
	setFilterFields: (newStringFilterDetails: FilterDetails) => void;
}

export default function StringFilterFields({ stringFilterDetails, setFilterFields }: StringFilterFieldsProps) {
	return (
		<>
			<h4 className="mb-4">Quicksight Parameter</h4>
			<input
				className="ebi__input ebi__input--white fullwidth"
				placeholder="Quicksight Parameter"
				value={stringFilterDetails.quicksightParameter}
				onChange={(e) => setFilterFields({...stringFilterDetails, quicksightParameter: e.target.value})}
				data-testid='filter-quicksight-parameter-input'/>
		</>
	);
};