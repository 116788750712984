import React from 'react';
import { TinyMCEAPIKey } from '../../Utility/Constants';
import { Editor } from "@tinymce/tinymce-react";

interface Props {
	value: string;
	onEditorChange: (content: string) => void;
}

export default function RichTextEditor({ value, onEditorChange }: Props) {
	return <Editor
		textareaName='richTextEditor'
		init={{
			menubar: false,
			statusbar: false,
			height: 300,
			plugins:defaultPlugins,
			toolbar: convertToToolbarStringFormat(defaultToolbarButtons),
		}}
		apiKey={TinyMCEAPIKey}
		onEditorChange={onEditorChange}
		value={value} />;
};

const defaultToolbarButtons = [
	['formatselect'],
	['bold', 'italic', 'underline', 'strikethrough'],
	['forecolor', 'backcolor'],
	['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
	['bullist', 'numlist'],
	['indent', 'outdent', 'blockquote'],
	['image', 'link', 'code'],
	['spellchecker'],
	['removeformat'],
];

const defaultPlugins = [
	'image',
	'table',
	'link',
	'lists',
	'powerpaste',
	'code',
	'spellchecker',
];

function convertToToolbarStringFormat(toolbarButtons: string[][]) {
	const formattedToolbarGroups = toolbarButtons.map((toolbarButtonGroup) => {
		return toolbarButtonGroup.join(' ');
	});

	return formattedToolbarGroups.join(' | ');
}