import React from 'react';

import './ReorderListRow.scss';

interface ReorderListRowProps {
	listItem: {
		title: string,
	};
	isFirst: boolean;
	isLast: boolean;
	moveListItemUp: () => void;
	moveListItemDown: () => void;
}

export default function ReorderListRow({ listItem, isFirst, isLast, moveListItemUp, moveListItemDown }: ReorderListRowProps) {
	return (
		<>
			<div className="listRow" data-testid="reorder-list-row">
				<div>
					<div className="listRow__title" data-testid="reorder-list-row-title">{listItem.title}</div>
				</div>

				<div className="flex align-items-center">
					<div style={{ width: '20px' }}>{!isFirst && <div className='arrow-up cursor-pointer' onClick={moveListItemUp} data-testid='reorder-list-row-up' ></div>}</div>
					<div style={{ width: '20px' }}>{!isLast && <div className='arrow-down cursor-pointer' onClick={moveListItemDown} data-testid='reorder-list-row-down'></div>}</div>
				</div>
			</div>
		</>
	);
};