import React, { useState, useEffect, useContext } from 'react';
import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import { SavedCategory } from '../../Services/Embeddedbi.types';
import ReorderList from '../../Components/ReorderList';
import Embeddedbi from '../../Services/Embeddedbi';


interface ReorderCategoriesProps {
	setPageTitle: (newTitle: string) => void;
	categoryList: SavedCategory[];
}

export default function ReorderCategories({ setPageTitle, categoryList }: ReorderCategoriesProps) {
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => setPageTitle('Reorder Categories'), [setPageTitle]);
	const redirectCallback = useContext(RedirectCallbackContext);

	const [initialList, setInitialList] = useState(categoryList);

	async function initializeCategoryList() {
		setIsLoading(true);
		const retrievedCategories = await Embeddedbi.getAllCategories();
		setIsLoading(false);
		setInitialList(retrievedCategories);
	}

	useEffect(() => {
		if (!(initialList && initialList.length)) {
			initializeCategoryList();
		}
	}, [initialList]);

	async function save(orderedList: SavedCategory[]) {
		setIsLoading(true);
		try {
			await Embeddedbi.setCategoriesOrder(orderedList);
			redirectCallback('/');
		} catch {
			alert('Something went wrong with updating the ordering, please try again.');
		}
		setIsLoading(false);
	}

	return (
		<ReorderList
			initialList={initialList}
			isLoading={isLoading}
			listType='Categories'
			save={(orderedList) => save(orderedList as SavedCategory[])}
			cancel={() => redirectCallback('/')} />
	)
}