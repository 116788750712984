type ConditionalClasses = { [key: string]: any };
type Option = ConditionalClasses | string;

export function getActiveClasses(...classes: Option[]): string {
	// @ts-ignore
	return [].concat(...classes).reduce((result: string, option: Option) => {
		const nextClasses: string = typeof option === 'object' ? joinClasses(option) : option;
		const separator = result && nextClasses ? ' ' : '';
		return result + separator + nextClasses;
	}, '');
}

function joinClasses(classes: ConditionalClasses): string {
	return Object.keys(classes)
		.filter(key => classes[key])
		.join(' ');
}
