import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { DataSelectFilterDetails, FilterDetails } from '../../../Services/Embeddedbi.types';
import { SelectOption } from '../BaseFilterFields/BaseFilterFields';

interface DataSelectFilterFieldsProps {
	dataSelectFilterDetails: DataSelectFilterDetails,
	setFilterFields: (newDataSelectDetails: FilterDetails) => void;
}

const DataSelectTypes = [
	{ value: 'company', label: 'Company'},
	{ value: 'companyStatus',label: 'Company Status'},
	{ value: 'contractStatus', label: 'Contract Status'},
	{ value: 'contractType', label: 'Contract Type'},
	{ value: 'issueStatus', label: 'Issue Status'},
	{ value: 'issueType', label: 'Issue Type'},
	{ value: 'jobStatus', label: 'Job Status'},
	{ value: 'jobType',label: 'Job Type'},
	{ value: 'prospectStatus', label: 'Prospect Status'},
	{ value: 'prospectType', label: 'Prospect Type'},
	{ value: 'staff',label: 'Staff'},
	{ value: 'staffGroup', label: 'Staff Group'},
];

export default function DataSelectFilterFields({ dataSelectFilterDetails, setFilterFields }: DataSelectFilterFieldsProps) {
	
	const [ dataSelectType, setDataSelectType] = useState<SelectOption>(setInitialDataSelectType());

	function setInitialDataSelectType() {
		const existingDataSelectType = DataSelectTypes.find(type => type.value  === dataSelectFilterDetails.dataSelectType);
		return existingDataSelectType || DataSelectTypes[0];
	}

	useEffect(() => {
		if(dataSelectFilterDetails.dataSelectType !== dataSelectType.value){
			setFilterFields({...dataSelectFilterDetails, dataSelectType: dataSelectType.value})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	function handleDataSelectTypeChange(newDataSelectType: SelectOption) {
		setDataSelectType(newDataSelectType);
		setFilterFields({...dataSelectFilterDetails, dataSelectType: newDataSelectType.value})
	}

	return (
		<>
			<h4 className="mb-4">Quicksight Parameter</h4>
			<input
				className="ebi__input ebi__input--white fullwidth"
				placeholder="Quicksight Parameter"
				value={dataSelectFilterDetails.quicksightParameter}
				onChange={(e) => setFilterFields({...dataSelectFilterDetails, quicksightParameter: e.target.value})}
				data-testid='filter-quicksight-parameter-input'/>
			<div data-testid="data-select-filter-fields">
				<h4 className="mb-4">Data Select Type</h4>
				<Select
					options={DataSelectTypes}
					value={[dataSelectType]} 
					placeholder='Select Data Select Type' 
					onChange={(option) => handleDataSelectTypeChange(option as SelectOption)}/>
			</div>
		</>
	);
};
