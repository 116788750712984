import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import User from './User.types';
import Embeddedbi from './Services/Embeddedbi';

export interface AuthenticatedUser {
	user: User | null;
	doSignIn: () => void;
	doSignOut: () => void;
	getCurrentUser: () => void;
}

/* istanbul ignore next */
export const UserContext = createContext<AuthenticatedUser>({
	user: null,
	doSignIn: () => { },
	doSignOut: () => { },
	getCurrentUser: () => { },
});

// TODO - resolve cognitoUser typing when https://github.com/aws-amplify/amplify-js/issues/4927 is resolved
const parseCognitoUser = (cognitoUser: any): User | null => {
	const id = cognitoUser?.signInUserSession?.idToken || {};
	if (id.jwtToken) {
		const userGroups: string[] = id.payload ? id.payload['cognito:groups'] : [];

		return {
			jwt: id.jwtToken,
			email: id.payload?.email || '',
			name: id.payload?.name || '',
			isAdmin: userGroups?.find(group => group === 'ebi_control_panel_admins') ? true : false,
		};
	}
	return null;
};

export const UserProvider = ({ children }: { children: any }) => {
	const [user, setUser] = useState<User | null>(null);

	function updateEmbeddedBiService(authenticatedUser: User | null) {
		Embeddedbi.authenticatedUser = authenticatedUser;
		Embeddedbi.refreshSession = getCurrentUser;
	}

	const setUserState = (newUserState: User | null): void => {
		setUser(newUserState);
		updateEmbeddedBiService(newUserState);
	};

	const doSignIn = async (): Promise<void> => {
		try {
			const cognitoUser = await Auth.federatedSignIn();
			const parsedCognitoUser: User | null = parseCognitoUser(cognitoUser);
			setUserState(parsedCognitoUser);
		} catch (e) {
			console.error('Failed to sign in', e);
		}
	};

	const doSignOut = async (): Promise<void> => {
		try {
			await Auth.signOut();
			setUserState(null);
		} catch (e) {
			console.error('Failed to sign out', e);
		}
	};

	const getCurrentUser = (): void => {
		// get existing user data from cognito
		Auth.currentAuthenticatedUser()
			.then(parseCognitoUser)
			.then(setUserState)
			.catch(() => {
				setUserState(null);
			});
	};

	useEffect(getCurrentUser, []);

	// Make sure to not force a re-render on the components that are reading these values,
	// unless the `user` value has changed. This is an optimisation that is mostly needed in cases
	// where the parent of the current component re-renders and thus the current component is forced
	// to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
	// same value as long as the user data is the same. If you have multiple other "controller"
	// components or Providers above this component, then this will be a performance booster.
	const values = {
		...useMemo(() => ({ user }), [user]),
		doSignIn,
		doSignOut,
		getCurrentUser,
	};

	// Finally, return the interface that we want to expose to our other components
	return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abstracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = (): AuthenticatedUser => useContext(UserContext);
