import React from 'react';
import Select from 'react-select';
import moment from 'moment';
import { DateFilterParameterOffsetDetails, DateFilterParameterOffsetDetailsBound, DateFilterParameterOffsetDetailsSign, DateFilterParameterOffsetDetailsType } from '../../../../Services/Embeddedbi.types';

const OffsetSigns = [
	{ value: 'add', label: 'Add' },
	{ value: 'subtract', label: 'Subtract' }
];

const OffsetTypes = [
	{ value: 'days', label: 'Days' },
	{ value: 'weeks', label: 'Weeks' },
	{ value: 'months', label: 'Months' },
	{ value: 'years', label: 'Years' }
];

interface SelectOption {
	label: string,
	value: string,
};

interface Props {
	dataTestID?: string;
	offsetDetails: DateFilterParameterOffsetDetails;
	setOffset: (newOffsetDetails: DateFilterParameterOffsetDetails) => void;
	offsetBoundOptions: SelectOption[];
}

export default function RelativeToToday({ dataTestID, offsetDetails, setOffset, offsetBoundOptions }: Props) {
	function handleBoundChange(option: SelectOption) {
		setOffset({...offsetDetails, bound: option.value as DateFilterParameterOffsetDetailsBound});
	}

	function handleSignChange(option: SelectOption) {
		setOffset({...offsetDetails, offsetSign: option.value as DateFilterParameterOffsetDetailsSign});
	}

	function handleTypeChange(option: SelectOption) {
		setOffset({...offsetDetails, offsetType: option.value as DateFilterParameterOffsetDetailsType});
	}

	return (
		<div className='flex' data-testid={ dataTestID }>
			<Select
				className='mb-8 fullwidth mr-8'
				options={offsetBoundOptions}
				value={offsetBoundOptions.find(bound => bound.value === offsetDetails.bound)}
				onChange={(option) => handleBoundChange(option as SelectOption)} />
			{ (offsetDetails.bound !== 'none' && offsetDetails.bound !== 'any') &&
				<>
					<Select
						className='mb-8 fullwidth mr-8'
						options={OffsetSigns}
						value={OffsetSigns.find(sign => sign.value === offsetDetails.offsetSign)}
						onChange={(option) => handleSignChange(option as SelectOption)} />
					<input
						type='number'
						className='ebi__input ebi__input--white mb-8 mr-8'
						value={offsetDetails.offsetAmount}
						min='0'
						onChange={(e) => setOffset({...offsetDetails, offsetAmount: parseInt(e.target.value, 10)})}
						data-testid='filter-offset-amount-input'/>
					<Select
						className='mb-8 fullwidth mr-8'
						options={OffsetTypes}
						value={OffsetTypes.find(type => type.value === offsetDetails.offsetType)}
						onChange={(option) => handleTypeChange(option as SelectOption)} />
					<div className='flex-no-shrink flex align-items-center'>e.g. {buildRelativeToTodaySentenceStructure(offsetDetails)}</div>
				</>
			}
		</div>
	)
}

interface sentenceBuilderProps {
	bound: string;
	offsetSign?: string;
	offsetAmount?: number;
	offsetType?: string;
}

export function buildRelativeToTodaySentenceStructure({bound, offsetSign, offsetAmount, offsetType}: sentenceBuilderProps) {
	if (bound === 'none' || bound === 'any') {
		return '';
	}
	const offsetDate = calculateDateFromOffset({ bound, offsetSign, offsetAmount, offsetType });
	return offsetDate ? offsetDate.format('D-MMM-YYYY') : '';
}

const setBound: any = {
	today: () => { return moment() },
	startOfWeek: () => { return moment().startOf('week') },
	startOfMonth: () => { return moment().startOf('month') },
	startOfYear: () => { return moment().startOf('year') },
	endOfWeek: () => { return moment().endOf('week') },
	endOfMonth: () => { return moment().endOf('month') },
	endOfYear: () => { return moment().endOf('year') },
}


export function calculateDateFromOffset({bound, offsetSign, offsetAmount, offsetType}: sentenceBuilderProps) {
	if (bound === 'none' || bound === 'any') {
		return null;
	}
	
	const date = setBound[bound]();
	if (offsetSign === 'add') {
		return date.add(offsetAmount, offsetType);
	}
	else {
		return date.subtract(offsetAmount, offsetType);
	}
}