import React, { useState, useEffect, useContext } from 'react';
import CategoryRow from '../../Components/CategoryRow';

import Embeddedbi from '../../Services/Embeddedbi';
import { SavedCategory } from '../../Services/Embeddedbi.types';
import LoadingOverlay from '../../Components/LoadingOverlay';
import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import { useUser } from '../../User';


interface Props {
	setPageTitle: (newTitle: string) => void;
}

export default function CategoryList({ setPageTitle }: Props) {
	useEffect(() => setPageTitle('All Categories'), [setPageTitle]);

	const redirectCallback = useContext(RedirectCallbackContext);
	const { user } = useUser();

	const [categoryList, setCategoryList] = useState<SavedCategory[]>([]);

	const [isLoading, setIsLoading] = useState(true);

	const [valuesHaveUpdated, setValuesHaveUpdated] = useState(false);

	useEffect(() => {
		(async () => await initializeCategoryList())();
	}, []);

	useEffect(() => {
		if (valuesHaveUpdated) {
			(async () => await initializeCategoryList())();
		}
	}, [valuesHaveUpdated]);

	async function initializeCategoryList() {
		setIsLoading(true);
		try {
			const retrievedCategories = await Embeddedbi.getAllCategories();
			setIsLoading(false);
			setCategoryList(retrievedCategories);
			setValuesHaveUpdated(false);
		}
		catch {
			setIsLoading(false);
			alert('Something went wrong while loading the page, please try again.');
		}
	}

	if (categoryList.length || isLoading) {
		return (
			<div data-testid="test-category-list">
				{isLoading && <LoadingOverlay />}
				{!isLoading && <div className="flex flex-end">
					<button
						className="cta cta--secondary m-8"
						onClick={() => redirectCallback('/category/list/reorder', categoryList)}
						data-testid="reorder-categories-button"
						disabled={!user?.isAdmin}
					>
						Reorder Categories
					</button>
				</div>}
				<div className="background-f9f9f9">
					{categoryList.map((category: SavedCategory) => (
						<CategoryRow key={category.id} expanded category={category} setValuesHaveUpdated={() => setValuesHaveUpdated(true)} />
					))}
				</div>
			</div>
		)
	} else {
		return <div data-testid="test-category-list">There are no categories available</div>
	}
};

