import React from 'react';
import { useUser } from '../../User';

import './SignInPanel.scss';

export default function SignInPanel() {
    const { doSignIn } = useUser();
    return (
        <div className="signin-panel-box" data-testid="siginin-panel-box">
            <p>You are not signed in</p>
            <p>
                <button
                    className="signin-button"
                    data-testid="signin-button"
                    onClick={doSignIn}>
                    Sign In
                </button>
            </p>
        </div>
    );
}
