import React from "react";
import './MetadataView.scss';

import { Metadata } from "./MetadataView.types";

interface Props {
	metadata: Metadata;
}

export default function MetadataView({ metadata }: Props) {
	return (<div className="metaData">
		<div className="mb-8">Metadata</div>
		<div className="ml-16">
			<div className="mb-4">Created</div>
			<div className="ml-16 mb-8">
				<div className="mb-4" data-testid='metadata-created-by'>By: {metadata.createdBy}</div>
				<div className="mb-4" data-testid='metadata-created-date'>Date: {convertToDate(metadata.created)}</div>
			</div>
			<div className="mb-4">Updated</div>
			<div className="ml-16 mb-4">
				<div className="mb-4" data-testid='metadata-updated-by'>By: {metadata.lastUpdatedBy}</div>
				<div className="mb-4" data-testid='metadata-updated-date'>Date: {convertToDate(metadata.lastUpdated)}</div>
			</div>
		</div>
	</div>);
}

export function convertToDate(utcDate: string) {
	const date = new Date(utcDate);
	const locale = window.navigator.language;
	return date.toLocaleString(locale, { hour12: true });
}
