import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from './App';
import { UserProvider } from './User';
import { BUGSNAG_API_KEY } from './Config/Config';

Bugsnag.start({
	apiKey: BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: ['production', 'staging'],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const children = (
	<UserProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</UserProvider>
);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children);
