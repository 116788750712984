import React from 'react';
import { ParameterDetails } from '../../Services/Embeddedbi.types';

interface ParameterProps {
	parameterDetails: ParameterDetails;
	setParameterFields: (newParameterDetails: ParameterDetails) => void;
	removeParameter: () => void;
}

export default function ParameterFields({ parameterDetails, setParameterFields, removeParameter }: ParameterProps) {
	return (
		<div data-testid="parameter-inputs">
			<div className="flex flex-end">
				<div className="textOnlyButton" onClick={removeParameter} data-testid="dashboard-parameter-group-remove">x</div>
			</div><h4 className="mb-4">Quicksight Parameter</h4>
			<input
				className="ebi__input ebi__input--white fullwidth mb-8"
				placeholder="Quicksight Parameter"
				value={parameterDetails.quicksightParameter}
				onChange={(e) => setParameterFields({...parameterDetails, quicksightParameter: e.target.value})}
				data-testid='parameter-quicksight-input'/>
			<h4 className="mb-4">Value</h4>
			<input
				className="ebi__input ebi__input--white fullwidth mb-8"
				placeholder="Value"
				value={parameterDetails.stringValue}
				onChange={(e) => setParameterFields({...parameterDetails, stringValue: e.target.value})}
				data-testid='parameter-value-input'/>
		</div>
	);
};