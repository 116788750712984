import React, { useState, useEffect } from 'react';
import deepEqual from 'deep-equal';
import ReorderListRow from "./ReorderListRow";
import LoadingOverlay from '../LoadingOverlay';
import { useUser } from '../../User';

export type ListItem = {
	title: string;
	ordering: number;
};

interface Props {
	initialList: ListItem[];
	isLoading: boolean;
	listType: string;
	save: (orderedList: ListItem[]) => void;
	cancel: () => void;
}

export default function ReorderList({ initialList, isLoading, listType, save, cancel }: Props) {
	const [orderedList, setOrderedList] = useState(initialList);
	const [canSave, setCanSave] = useState(false);
	const { user } = useUser();

	useEffect(() => {
		setOrderedList(initialList);
	}, [initialList]);

	function moveListItem({ index, direction }: { index: number, direction: 'up' | 'down' }) {
		const directionAddition = direction === 'up' ? -1 : 1;
		let newOrderedList = [...orderedList];
		const movedListItem = newOrderedList[index];
		newOrderedList[index] = newOrderedList[index + directionAddition];
		newOrderedList[index + directionAddition] = movedListItem;
		setOrderedList(setOrdering(newOrderedList));
		setCanSave(!deepEqual(newOrderedList, initialList));
	}

	if (isLoading) {
		return (<LoadingOverlay />)
	}
	else if (orderedList && orderedList.length) {
		return (
			<>
				<div className="background-f9f9f9" data-testid="test-reorder-list">
					{orderedList.map((listItem: ListItem, index) => (
						<ReorderListRow
							key={index}
							listItem={listItem}
							isFirst={index === 0}
							isLast={index + 1 === orderedList.length}
							moveListItemUp={() => moveListItem({ index: index, direction: 'up' })}
							moveListItemDown={() => moveListItem({ index: index, direction: 'down' })}
						/>
					))}
				</div>
				<div className="flex align-items-center flex-end p-16">
					<button className="cta cta--secondary mr-8" onClick={cancel} data-testid="test-reorder-list-cancel">Cancel</button>
					<button
						className="cta cta--blue"
						disabled={!canSave || !user?.isAdmin}
						onClick={() => save(orderedList)}
						data-testid="test-reorder-list-save"
					>
						Save
					</button>
				</div>
			</>
		)
	} else {
		return (<div>No {listType} Found...</div>);
	}
};

export function setOrdering(orderedList: ListItem[]) {
	const updatedList = [...orderedList];
	for (let i = 0; i < updatedList.length; i++) {
		updatedList[i].ordering = i + 1;
	};
	return updatedList;
}