import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Header from '../Components/Header';
import CategoryList from '../Containers/CategoryList';
import CreateCategory from '../Containers/CreateCategory';
import ReorderCategories from '../Containers/ReorderCategories';
import CreateDashboard from '../Containers/CreateDashboard';
import { useUser } from '../User';

import RedirectCallbackContext from '../Contexts/RedirectCallbackContext';
import ReorderDashboards from '../Containers/ReorderDashboards';

export default function EbiRoutes() {
	const [pageTitle, setPageTitle] = useState('');
	const [redirectProps, setRedirectProps] = useState<any>();
	const { doSignOut } = useUser();
	const navigate = useNavigate();

	function onClickEditRedirectHandler(redirectURL: string, redirectProps?: any) {
		setRedirectProps(redirectProps);
		navigate(redirectURL);
	}

	return (
		<div data-testid="root-app">
			<RedirectCallbackContext.Provider
				value={(redirectURL, redirectProps) => onClickEditRedirectHandler(redirectURL, redirectProps)}
			>
				<Header pageTitle={pageTitle} logOut={doSignOut} />
				<Routes>
					<Route path="/" element={<CategoryList setPageTitle={(newTitle) => setPageTitle(newTitle)} />} />
					<Route
						path="/category/create"
						element={<CreateCategory setPageTitle={(newTitle) => setPageTitle(newTitle)} />}
					/>
					<Route
						path="/category/edit"
						element={
							<CreateCategory setPageTitle={(newTitle) => setPageTitle(newTitle)} gotCategoryDetails={redirectProps} />
						}
					/>
					<Route
						path="/category/list/reorder"
						element={
							<ReorderCategories setPageTitle={(newTitle) => setPageTitle(newTitle)} categoryList={redirectProps} />
						}
					/>
					<Route
						path="/dashboard/create"
						element={<CreateDashboard setPageTitle={(newTitle) => setPageTitle(newTitle)} />}
					/>
					<Route
						path="/dashboard/edit"
						element={
							<CreateDashboard setPageTitle={(newTitle) => setPageTitle(newTitle)} dashboardId={redirectProps} />
						}
					/>
					<Route
						path="/dashboard/list/reorder"
						element={<ReorderDashboards setPageTitle={(newTitle) => setPageTitle(newTitle)} category={redirectProps} />}
					/>
				</Routes>
			</RedirectCallbackContext.Provider>
		</div>
	);
}
