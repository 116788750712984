import React from 'react';
import Select from 'react-select';

import { FilterDetails } from '../../../Services/Embeddedbi.types';
import { defaultFieldTypeMap } from './DefaultFieldTypeMap'

export interface SelectOption {
	label: string,
	value: string,
};

type FilterTypeValues = 'string' | 'dataSelect' | 'dateRange' | 'customSelect';

interface FilterProps {
	filterDetails: FilterDetails;
	setFilterFields: (newFilterDetails: FilterDetails) => void;
}

const FilterTypes = [
	{ value: 'string', label: 'String' },
	{ value: 'dataSelect', label: 'Data Select'},
	{ value: 'dateRange', label: 'Date Range' },
	{ value: 'customSelect', label: 'Custom Select'}
];

export default function BaseFilterFields({ filterDetails, setFilterFields }: FilterProps) {
	function handleFilterTypeChange(newFilterTypeOption: SelectOption) {
		if(filterDetails.type === newFilterTypeOption.value){
			return;
		}

		// Reset the filter and only keep the common filter attributes
		const {id, label } = filterDetails;
		setFilterFields({id, label, ...defaultFieldTypeMap[newFilterTypeOption.value as FilterTypeValues], type: newFilterTypeOption.value} as FilterDetails);
	}

	function getInitialFilterType(){
		const existingDataSelectType = FilterTypes.find(type => type.value  === filterDetails.type);
		return existingDataSelectType || FilterTypes[0];
	}

	return (
		<div data-testid="base-filter-inputs">
			<h4 className="mb-4">Type</h4>
			<Select
				className='mb-8'
				options={FilterTypes}
				value={[getInitialFilterType()]} 
				placeholder='Select Filter Type'
				onChange={(option) => handleFilterTypeChange(option as SelectOption)}/>
			<h4 className="mb-4">Label</h4>
			<input
				className="ebi__input ebi__input--white fullwidth mb-8"
				placeholder="Label"
				value={filterDetails.label}
				onChange={(e) => setFilterFields({...filterDetails, label: e.target.value})}
				data-testid='filter-label-input'/>
		</div>
	);
};