import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import { CustomSelectFilterDetails, CustomSelectFilterOption, FilterDetails } from '../../../Services/Embeddedbi.types';

import './CustomSelectFilterFields.scss';

interface CustomSelectFilterFieldsProps {
	dataSelectFilterDetails: CustomSelectFilterDetails,
	setFilterFields: (newCustomSelectDetails: FilterDetails) => void;
}

export default function CustomSelectFilterFields({ dataSelectFilterDetails, setFilterFields }: CustomSelectFilterFieldsProps) {
	const [customOptionsAreValid, setCustomOptionsAreValid] = useState(true);
	function addCustomSelectOption() {
		if(canAddNewSelectOption(dataSelectFilterDetails.options)) {
			const newOption = {name: '', value: ''};
			const tempOptions = [...dataSelectFilterDetails.options];
			tempOptions.push(newOption);
			setFilterFields({...dataSelectFilterDetails, options: tempOptions});
		}
		else {
			setCustomOptionsAreValid(false);
		}
	}
	
	function updateCustomSelectOption(option: CustomSelectFilterOption, index: number) {
		const tempOptions = [...dataSelectFilterDetails.options];
		tempOptions[index] = option;
		setFilterFields({...dataSelectFilterDetails, options: tempOptions});
		setCustomOptionsAreValid(isSelectOptionsValid(tempOptions));
	}

	function removeCustomSelectOption(index: number) {
		const tempOptions = [...dataSelectFilterDetails.options];
		const newOptions = tempOptions.filter((option, optionIndex) => optionIndex !== index);
		setFilterFields({...dataSelectFilterDetails, options: newOptions});
		setCustomOptionsAreValid(isSelectOptionsValid(newOptions));
	}

	return (
		<>
			<h4 className="mb-4">Quicksight Parameter</h4>
			<input
				className="ebi__input ebi__input--white fullwidth"
				placeholder="Quicksight Parameter"
				value={dataSelectFilterDetails.quicksightParameter}
				onChange={(e) => setFilterFields({...dataSelectFilterDetails, quicksightParameter: e.target.value})}
				data-testid='dashboard-custom-select-quicksight-parameter-input'/>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checked={ dataSelectFilterDetails.isMulti }
							onChange={() => setFilterFields({ ...dataSelectFilterDetails, isMulti: !dataSelectFilterDetails.isMulti })}
							data-testid='dashboard-custom-select-is-multi' />}
					label="Is Multi" />
			</div>
			<div className="flex space-between">
				<h4 className="mb-4">Options</h4>
				<div className="textOnlyButton" onClick={addCustomSelectOption} data-testid="dashboard-custom-select-add-option">+ add</div>
			</div>
			{dataSelectFilterDetails.options.length > 0 && 
				<div className="customSelectFilterOptions background-f1f1f1">
					{dataSelectFilterDetails.options.map((option, index) => (
						<div className="flex align-items-center" data-testid='dashboard-custom-select-option' key={index}>
							<input
								className="ebi__input ebi__input--white fullwidth mr-4"
								placeholder="Name"
								value={option.name}
								onChange={(e) => updateCustomSelectOption({name: e.target.value, value: option.value}, index)}
								data-testid='dashboard-custom-select-option-name'/>
							<input
								className="ebi__input ebi__input--white fullwidth ml-4"
								placeholder="Value"
								value={option.value}
								onChange={(e) => updateCustomSelectOption({name: option.name, value: e.target.value}, index)}
								data-testid='dashboard-custom-select-option-value'/>
							<div className="remove ml-8" onClick={() => removeCustomSelectOption(index)} data-testid="dashboard-custom-select-remove-option">x</div>
						</div>
					))}
					{!customOptionsAreValid && <div data-testid="dashboard-custom-select-option-error">* please ensure all options have a name and values are unique</div>}
				</div>
			}
		</>
	);
};

function canAddNewSelectOption(options: CustomSelectFilterOption[]) {
	if( options.length === 0) {
		return true;
	}

	return isSelectOptionValid(options);
}

export function isSelectOptionsValid(options: CustomSelectFilterOption[]) {
	if( options.length === 0) {
		return false;
	}

	return isSelectOptionValid(options);
}

function isSelectOptionValid (options: CustomSelectFilterOption[]) {
	const invalidEmptyFields = options.filter(option => {
		return option.value === '' || option.name === '';
	});

	if (invalidEmptyFields.length) {
		return false;
	}

	const uniqueOptionValues = new Set(options.map(option => option.value));

	return options.length === uniqueOptionValues.size;
}