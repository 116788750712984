export const TinyMCEAPIKey = "w3b7i18x9xgcstvw3hffeo17w4vbtyazstq6l7agmdljtoss";

export const regions = ['us-west-2', 'ap-southeast-2', 'eu-west-1'];

interface RegionLabels {
	[key: string]: string;
}

export const regionLabels: RegionLabels = {
	'us-west-2': 'US',
	'ap-southeast-2': 'AU',
	'eu-west-1': 'EU',
};
