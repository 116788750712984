const dateRangeOffsetDefault  = {
	bound: 'none',
	offsetSign: 'add',
	offsetAmount: 0,
	offsetType: 'days',
}

export const defaultFieldTypeMap = {
	string: {
		quicksightParameter: '',
	},
	dataSelect: {
		quicksightParameter: '',
		dataSelectType: 'assetStatus',
	},
	dateRange: {
		fromOffset: {
			quicksightParameter: '',
			default: dateRangeOffsetDefault,
			isEditable: true,
		},
		toOffset: {
			quicksightParameter: '',
			default: dateRangeOffsetDefault,
			isEditable: true,
		},
		validRange: {
			fromOffset: {...dateRangeOffsetDefault, bound: 'any'},
			toOffset: {...dateRangeOffsetDefault, bound: 'any'},
		},
	},
	customSelect: {
		quicksightParameter: '',
		isMulti: false,
		options: [],
	}
}