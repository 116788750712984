import React from 'react';
import Amplify from 'aws-amplify';

import { useUser } from './User';
import SignInPanel from './Components/SignInPanel';
import Routes from './Routes/Routes';
import { AWS_CONFIG } from './Config/Config'

import './App.scss';

Amplify.configure(AWS_CONFIG);

export default function App() {
	const { user } = useUser();

	return (
		<div className="App" data-testid="app">
			{!user && <SignInPanel />}
			{user && <Routes />}
		</div>
	);
}
