import React, { useContext } from 'react';
import './Header.scss';

import getActiveClasses from '../../Utility/active-classes';
import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import { useUser } from '../../User';

interface Props {
	pageTitle: String;
	logOut: () => void;
}

export default function Header({ pageTitle, logOut }: Props) {
	const redirectCallback = useContext(RedirectCallbackContext);
	const { user } = useUser();

	return (
		<div className="header" data-testid="app-header">

			<div className="header__appTitleBar">
				<h1 className="header__appTitleBar__LHS" onClick={() => redirectCallback('/')} data-testid="home-page-link">Embedded BI Control Panel</h1>
				<button className="header__appTitleBar__RHS__logout" onClick={() => logOut()} data-testid="header-logout-button">
					Sign out
				</button>
			</div>

			<div className="header__navBar">
				<h2 className="header__navBar__LHS__title">{pageTitle}</h2>
				<button
					className={getActiveClasses(["header__navBar__LHS__button mr-8", { "header__navBar__LHS__button--disabled": !user?.isAdmin }])}
					onClick={() => redirectCallback('/category/create')}
					data-testid="create-category-link"
					disabled={!user?.isAdmin}
				>
					Add Category
				</button>
				<button
					className={getActiveClasses(["header__navBar__LHS__button", { "header__navBar__LHS__button--disabled": !user?.isAdmin }])}
					onClick={() => redirectCallback('/dashboard/create')}
					data-testid="create-dashboard-link"
					disabled={!user?.isAdmin}
				>
					Add Dashboard
				</button>
			</div>
		</div>
	);
}