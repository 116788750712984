import React from 'react';
import ToggleSwitch from '../ToggleSwitch';

import './TitledToggleSwitch.scss';

interface Props {
	title: string,
	value: boolean,
	setValue: (newValue: any) => void;
}

export default function TitledToggleSwitch({ title, value, setValue }: Props) {
	return (
		<>
			<h4 className="titledToggleSwitch__title mr-16">{title}</h4>
			<ToggleSwitch value={value} setValue={newStatus => setValue(newStatus)} />
		</>
	)
}