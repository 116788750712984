import React from 'react';
import moment from 'moment';

import { DateRangeFilterDetails, DateFilterParameterOffsetDetails, DateFilterParameterDetails } from '../../../Services/Embeddedbi.types';
import { Checkbox, FormControlLabel } from '@mui/material';
import RelativeToToday, { calculateDateFromOffset } from './RelativeToToday';

interface DateRangeFilterFieldsProps {
	dateRangeFilterDetails: DateRangeFilterDetails,
	setFilterFields: (newDateRangeFilterDetails: any) => void;
}

const DefaultBounds = [
	{ value: 'today', label: 'Today' },
	{ value: 'startOfWeek', label: 'Start Of Week' },
	{ value: 'startOfMonth', label: 'Start Of Month' },
	{ value: 'startOfYear', label: 'Start Of Year' },
	{ value: 'endOfWeek', label: 'End Of Week' },
	{ value: 'endOfMonth', label: 'End Of Month' },
	{ value: 'endOfYear', label: 'End Of Year' }
];

export const anyBounds = [{value: 'any', label: 'Any'}].concat(DefaultBounds);
export const noneBounds = [{value: 'none', label: 'None'}].concat(DefaultBounds);

export default function DateRangeFilterFields({ dateRangeFilterDetails, setFilterFields }: DateRangeFilterFieldsProps) {
	return (
		<>
			<h4>From Date</h4>
			<div className="p-8 background-f1f1f1 borderRadius-5 mb-8" data-testid='filter-from-offset'>
				<h5 className="mb-4">QuickSight Parameter</h5>
				<input
					className="ebi__input ebi__input--white fullwidth mb-8"
					placeholder="QuickSight Parameter"
					value={ dateRangeFilterDetails.fromOffset.quicksightParameter } 
					onChange={(e) => setFilterFields({...dateRangeFilterDetails, fromOffset: {...dateRangeFilterDetails.fromOffset, quicksightParameter: e.target.value }})}
					data-testid='filter-from-offset-quicksightparameter'/>
				<h5 className="mb-4">Default</h5>
				<RelativeToToday 
					offsetBoundOptions={noneBounds}
					offsetDetails={dateRangeFilterDetails.fromOffset.default}
					setOffset={(newOffsetDetails: DateFilterParameterOffsetDetails) => setFilterFields({...dateRangeFilterDetails, fromOffset: {...dateRangeFilterDetails.fromOffset, default: newOffsetDetails }})} />
				<FormControlLabel
					control={
						<Checkbox
							checked={ dateRangeFilterDetails.fromOffset.isEditable }
							onChange={() => setFilterFields({...dateRangeFilterDetails, fromOffset: {...dateRangeFilterDetails.fromOffset, isEditable: !dateRangeFilterDetails.fromOffset.isEditable}})}
							data-testid='filter-from-offset-is-editable' />}
					label="Is Editable" />
				{isInvalidDateOffset(dateRangeFilterDetails.fromOffset) && (<div data-testid="create-dashboard-from-offset-error">* Filter default can not be set to 'None' when the default is not editable</div>)}
			</div>
			<h4>To Date</h4>
			<div className="p-8 background-f1f1f1 borderRadius-5 mb-8" data-testid='filter-to-offset'>
				<h5 className="mb-4">QuickSight Parameter</h5>
				<input
					className="ebi__input ebi__input--white fullwidth mb-8"
					placeholder="QuickSight Parameter"
					value={ dateRangeFilterDetails.toOffset.quicksightParameter } 
					onChange={(e) => setFilterFields({...dateRangeFilterDetails, toOffset: {...dateRangeFilterDetails.toOffset, quicksightParameter: e.target.value }})}
					data-testid='filter-to-offset-quicksightparameter'/>
				<h5 className="mb-4">Default</h5>
				<RelativeToToday
					offsetBoundOptions={noneBounds}
					offsetDetails={dateRangeFilterDetails.toOffset.default}
					setOffset={(newOffsetDetails: DateFilterParameterOffsetDetails) => setFilterFields({...dateRangeFilterDetails, toOffset: {...dateRangeFilterDetails.toOffset, default: newOffsetDetails }})} />
				<FormControlLabel
					control={
						<Checkbox
							checked={ dateRangeFilterDetails.toOffset.isEditable }
							onChange={() => setFilterFields({ ...dateRangeFilterDetails, toOffset: { ...dateRangeFilterDetails.toOffset, isEditable: !dateRangeFilterDetails.toOffset.isEditable}})}
							data-testid='filter-to-offset-is-editable' />
					}
					label="Is Editable" />
				{isInvalidDateOffset(dateRangeFilterDetails.toOffset) && (<div data-testid="create-dashboard-to-offset-error">* Filter default can not be set to 'None' when the default is not editable</div>)}
				{isDateRangeInvalid({fromOffset: dateRangeFilterDetails.fromOffset.default, toOffset: dateRangeFilterDetails.toOffset.default}) && (<div data-testid="create-dashboard-to-offset-range-error">* To date cannot be before From date</div>)}
			</div>
			<h4>Valid Range</h4>
			<div className="p-8 background-f1f1f1 borderRadius-5" data-testid='filter-range-offset'>
				<h5 className="mb-4">From</h5>
				<RelativeToToday
					offsetBoundOptions={anyBounds}
					offsetDetails={dateRangeFilterDetails.validRange.fromOffset}
					setOffset={(newOffsetDetails: DateFilterParameterOffsetDetails) => setFilterFields({...dateRangeFilterDetails, validRange: {...dateRangeFilterDetails.validRange, fromOffset: newOffsetDetails }})} />
				<h5 className="mb-4">To</h5>
				<RelativeToToday
					offsetBoundOptions={anyBounds}
					offsetDetails={dateRangeFilterDetails.validRange.toOffset}
					setOffset={(newOffsetDetails: DateFilterParameterOffsetDetails) => setFilterFields({...dateRangeFilterDetails, validRange: {...dateRangeFilterDetails.validRange, toOffset: newOffsetDetails }})} />
					{ isDateRangeInvalid(dateRangeFilterDetails.validRange) && (<div data-testid="create-dashboard-valid-range-offset-error">* Valid Range To date cannot be before From date</div>)}
			</div>
		</>
	);
};

export function isInvalidDateOffset(offset: DateFilterParameterDetails) {
	return (!offset.isEditable && offset.default.bound === 'none');
}

interface validRange {
	fromOffset: DateFilterParameterOffsetDetails;
	toOffset: DateFilterParameterOffsetDetails;
}
export function isDateRangeInvalid({fromOffset, toOffset}: validRange) {
	const fromOffsetDate = calculateDateFromOffset(fromOffset);
	const toOffsetDate = calculateDateFromOffset(toOffset);

	if(fromOffsetDate === null || toOffsetDate === null) {
		return false;
	}

	return !moment(fromOffsetDate).isSameOrBefore(moment(toOffsetDate));
}