import axios, { AxiosRequestConfig } from 'axios';

import { GATEWAY_BASE_URL } from '../Config/Config';
import { NewCategory, SavedCategory, NewDashboard, SavedDashboard } from './Embeddedbi.types';

import User from '../User.types';

axios.defaults.withCredentials = true;

export default class Embeddedbi {
	static authenticatedUser: User | null;
	static refreshSession: Function | undefined;

	static createCategory = async (category: NewCategory): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'post',
			url: 'category',
			data: category,
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static editCategory = async ({ category, categoryID }: any): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `category/${categoryID}`,
			data: category,
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static setCategoriesOrder = async (orderedCategories: SavedCategory[]): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'put',
			url: 'categories/order',
			data: orderedCategories,
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static getAllCategories = async (): Promise<SavedCategory[]> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: 'category/list'
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static getCategory = async (categoryId: string): Promise<SavedCategory[]> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: 'category/list',
			params: {id: categoryId}
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static setCategoryDashboardOrder = async ({orderedDashboards, categoryId}: {orderedDashboards: SavedDashboard[], categoryId: string}): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `category/${categoryId}/dashboard/reorder`,
			data: orderedDashboards,
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static createDashboard = async (dashboard: NewDashboard): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'post',
			url: 'dashboard',
			data: dashboard,
		};
		return await Embeddedbi.makeRequest(requestOptions);
	}

	static getDashboard = async (dashboardId: string): Promise<SavedDashboard> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `dashboard/${dashboardId}`
		};

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static editDashboard = async (dashboard: SavedDashboard): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `dashboard/${dashboard.id}`,
			data: { ...dashboard, id: undefined },
		}

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static publishDashboard = async (dashboardId: string, dashboardStatus: string): Promise<any> => {
		const requestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `dashboard/publish/${dashboardId}`,
			data: { status: dashboardStatus },
		}

		return await Embeddedbi.makeRequest(requestOptions);
	}

	static makeRequest = async (requestOptions: AxiosRequestConfig): Promise<any> => {
		if (!Embeddedbi.authenticatedUser) {
			throw new Error('Unable to make requests without a signed in authenticated user');
		}

		let response = await Embeddedbi.callGatewayWithAxios({ ...requestOptions, validateStatus: Embeddedbi.validateStatusAllowUnauthorized, });
		if (![401, 403].includes(response.status)) {
			return response.data;
		}

		// Previous request showed access denied --> refresh the session and try again
		await (Embeddedbi.refreshSession && Embeddedbi.refreshSession());

		if (Embeddedbi.authenticatedUser) {
			return (await Embeddedbi.callGatewayWithAxios(requestOptions)).data;
		} else {
			throw new Error('Unable to connect to Embeddedbi service');
		}
	}

	// TODO Find way to test axios calls that are made this way
	/* istanbul ignore next */
	private static callGatewayWithAxios = async (requestOptions: AxiosRequestConfig) => {
		const gatewayRequestOptions: AxiosRequestConfig = {
			headers: {
				Accept: 'application/json',
				'ebi-cognito-id-token': Embeddedbi.authenticatedUser?.jwt || '',
				email: Embeddedbi.authenticatedUser?.email || ''
			},
			baseURL: GATEWAY_BASE_URL,
			...requestOptions,
		};
		return axios(gatewayRequestOptions);
	}

	private static validateStatusAllowUnauthorized = (status: number) => ((status >= 200 && status < 300) || [401, 403].includes(status));
}
