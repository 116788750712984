import React from 'react';
import './ToggleSwitch.scss';

interface Props {
	value: boolean,
	setValue: (newValue: boolean) => void;
}

export default function ToggleSwitch({ value, setValue }: Props) {
	return (
		<label className="switch">
			<input type="checkbox" checked={value} onChange={() => setValue(!value)} data-testid="toggle-switch"/>
			<span className="slider round"></span>
		</label>
	)
}