import React, { useContext, useEffect, useState } from 'react';
import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import LoadingOverlay from '../../Components/LoadingOverlay';
import './CreateCategory.scss';

import Embeddedbi from '../../Services/Embeddedbi';
import { CategoryDetails } from './CreateCategory.types';
import CategoryFields from '../../Components/CategoryFields';

import './CreateCategory.scss';
import { NewCategory } from '../../Services/Embeddedbi.types';
import { useUser } from '../../User';

interface Props {
	setPageTitle: (newTitle: string) => void;
	gotCategoryDetails?: NewCategory;
}

export default function CreateCategory({ setPageTitle, gotCategoryDetails }: Props) {
	const isEdit = !!gotCategoryDetails;
	const pageTitle = isEdit ? 'Edit Category' : 'Create Category';

	const { user } = useUser();
	const redirectCallback = useContext(RedirectCallbackContext);

	useEffect(() => setPageTitle(pageTitle), [setPageTitle, pageTitle]);
	const [isLoading, setIsLoading] = useState(false);
	const [valuesHaveChanged, setValuesHaveChanged] = useState(false);

	const [categoryDetails, setCategoryDetails] = useState<CategoryDetails>(gotCategoryDetails ?
		{
			...gotCategoryDetails,
			title: {
				value: gotCategoryDetails.title,
				isValid: true,
			},
			description: gotCategoryDetails.description,
			modules: gotCategoryDetails.modules || [],
			status: gotCategoryDetails.status === 'active',
			created: gotCategoryDetails.created ? convertToDate(gotCategoryDetails.created) : '',
			lastUpdated: gotCategoryDetails.lastUpdated ? convertToDate(gotCategoryDetails.lastUpdated) : '',
		} : {
			title: {
				value: '',
				isValid: true,
			},
			description: '',
			modules: [],
			status: true,
		});

	function setTitle(newTitle: string) {
		const isValidTitle = isTitleValid(newTitle);
		setCategoryDetails({ ...categoryDetails, title: { value: newTitle, isValid: isValidTitle } });
		setValuesHaveChanged(true);
	}

	function setDescription(newDescription: string) {
		setCategoryDetails({ ...categoryDetails, description: newDescription });
		setValuesHaveChanged(true);
	}

	function setModules(newModules: string[]) {
		setCategoryDetails({ ...categoryDetails, modules: newModules });
		setValuesHaveChanged(true);
	}

	function setStatus(newStatus: boolean) {
		setCategoryDetails({ ...categoryDetails, status: newStatus });
		setValuesHaveChanged(true);
	}

	function setCategoryDetailsAreValid(): CategoryDetails {
		const updatedCategoryDetails = {
			...categoryDetails,
			title: {
				value: categoryDetails.title.value,
				isValid: isTitleValid(categoryDetails.title.value),
			},
			description: categoryDetails.description,
		};
		setCategoryDetails(updatedCategoryDetails);
		return updatedCategoryDetails;
	}

	async function saveCategory() {
		const updatedCategoryDetails = setCategoryDetailsAreValid();

		const invalidEntries = getInvalidEntries(updatedCategoryDetails);
		if (invalidEntries.length === 0) {
			if (valuesHaveChanged) {
				setIsLoading(true);
				try {
					isEdit ? await saveEditedCategory(updatedCategoryDetails) : await saveCreatedCategory(updatedCategoryDetails);
					redirectCallback('/');
				}
				catch {
					setIsLoading(false);
					alert('Something went wrong while saving the category, please try again.');
				}
			}
			else {
				redirectCallback('/');
			}
		}
	}

	async function saveCreatedCategory(updatedCategoryDetails: CategoryDetails) {
		return await Embeddedbi.createCategory(buildSaveDataStructure(updatedCategoryDetails));
	}

	async function saveEditedCategory(updatedCategoryDetails: CategoryDetails) {
		return await Embeddedbi.editCategory({ category: buildSaveDataStructure(updatedCategoryDetails), categoryID: gotCategoryDetails ? gotCategoryDetails.id : '' });
	}

	function cancel() {
		if (window.confirm("Are you sure you want to cancel?")) {
			redirectCallback('/', { replace: true });
		}
	}

	const setters = {
		title: (newTitle: string) => setTitle(newTitle),
		description: (newDescription: string) => setDescription(newDescription),
		modules: (newModules: string[]) => setModules(newModules),
		status: (newStatus: boolean) => setStatus(newStatus),
	}

	return (
		<div className="createCategory" data-testid="test-create-category">
			{isLoading && <LoadingOverlay />}
			<div className="flex space-between">
				<CategoryFields categoryDetails={categoryDetails} setters={setters} />
				{isEdit && <div className="metaData">
					<div className="mb-8">Metadata</div>
					<div className="ml-16">
						<div className="mb-4">Created</div>
						<div className="ml-16 mb-8">
							<div className="mb-4">By: {categoryDetails.createdBy}</div>
							<div className="mb-4">Date: {categoryDetails.created}</div>
						</div>
						<div className="mb-4">Updated</div>
						<div className="ml-16 mb-4">
							<div className="mb-4">By: {categoryDetails.lastUpdatedBy}</div>
							<div className="mb-4">Date: {categoryDetails.lastUpdated}</div>
						</div>
					</div>
				</div>}
			</div>
			<div className="flex align-items-center">
				<button className="cta mr-8" onClick={cancel} data-testid="create-category-cancel">
					Cancel
				</button>
				<button
					className="cta cta--blue"
					onClick={async () => await saveCategory()}
					data-testid="create-category-save"
					disabled={!user?.isAdmin}
				>
					Submit
				</button>
			</div>
		</div>
	);
}

function isTitleValid(title: string) {
	return title !== '';
}

function getInvalidEntries(categoryDetails: CategoryDetails) {
	return Object.entries(categoryDetails).filter((entry) => !entry[1].isValid && entry[1].isValid !== undefined);
}

function buildStatusValue(status: boolean) {
	return status ? 'active' : 'inactive';
}

function buildSaveDataStructure(details: CategoryDetails) {
	return {
		title: details.title.value.trim(),
		description: details.description.trim(),
		modules: details.modules,
		status: buildStatusValue(details.status),
	};
}

function convertToDate(utcDate: string) {
	const date = new Date(utcDate);
	return date.toLocaleString();
}

export { isTitleValid, getInvalidEntries, buildSaveDataStructure, convertToDate };
