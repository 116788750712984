import React, { useState, useContext } from 'react';

import DashboardRow from '../DashboardRow';
import './CategoryRow.scss';

import { SavedCategory } from '../../Services/Embeddedbi.types';

import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import getStatusColor from '../../Utility/StatusColor';
import { useUser } from '../../User';

interface Props {
	category: SavedCategory;
	expanded?: boolean;
	setValuesHaveUpdated: () => void;
}

export default function CategoryRow({ category, expanded, setValuesHaveUpdated }: Props) {
	const { user } = useUser();
	const [shouldShowDashboards, setShouldShowDashboards] = useState(expanded || false);
	const redirectCallback = useContext(RedirectCallbackContext);

	return (
		<>
			<div className="category" onClick={() => setShouldShowDashboards(!shouldShowDashboards)} data-testid="test-category-row">
				<div>
					<div className="category__LHS__title">{category.title}</div>
				</div>
				<div>
					<div className="categoryRow__status" style={{ color: getStatusColor(category.status) }}>{category.status}</div>
				</div>
				<div className="category__RHS">
					<button
						className="cta cta--secondary m-8"
						onClick={() => redirectCallback(`/dashboard/list/reorder?categoryId=${category.id}`, category)}
						data-testid="reorder-dashboards-button"
						disabled={!user?.isAdmin}
					>
						Reorder Dashboards
					</button>
					<button className="cta cta--minimal mr-16" onClick={() => redirectCallback('/category/edit', category)} data-testid="test-category-edit-button">{user?.isAdmin ? 'Edit' : 'View'}</button>
					<div style={{ width: '20px' }}><div className={shouldShowDashboards ? 'arrow-down' : 'arrow-left'}></div></div>
				</div>
			</div>
			{shouldShowDashboards && category.dashboards && (
				<div className="ml-24" data-testid="test-dashboard-row">
					{category.dashboards.map((dashboard) => (
						<DashboardRow key={dashboard.id} dashboard={dashboard} setValuesHaveUpdated={setValuesHaveUpdated} />
					))}
				</div>
			)}
		</>
	);
};
