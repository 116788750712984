import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AccessLevelRuleSet } from '../../Services/Embeddedbi.types';


interface AccessLevelRuleSetProps {
	accessLevelRuleSet: AccessLevelRuleSet;
	setAccessLevelRuleSet: (accessLevelRuleSet: AccessLevelRuleSet) => void;
}

export default function AccessLevelFields({accessLevelRuleSet, setAccessLevelRuleSet}: AccessLevelRuleSetProps) {
	return (
		<div className="flex flex-direction-column">
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={accessLevelRuleSet.admin} onChange={() => setAccessLevelRuleSet({ ...accessLevelRuleSet, admin: !accessLevelRuleSet.admin })} />}
				label="Admin"
				data-testid="dashboard-access-level-admin" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={accessLevelRuleSet.professional} onChange={() => setAccessLevelRuleSet({ ...accessLevelRuleSet, professional: !accessLevelRuleSet.professional })} />}
				label="Professional"
				data-testid="dashboard-access-level-professional" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={accessLevelRuleSet.contractor} onChange={() => setAccessLevelRuleSet({ ...accessLevelRuleSet, contractor: !accessLevelRuleSet.contractor })} />}
				label="Contractor"
				data-testid="dashboard-access-level-contractor" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={accessLevelRuleSet.collaborator} onChange={() => setAccessLevelRuleSet({ ...accessLevelRuleSet, collaborator: !accessLevelRuleSet.collaborator })} />}
				label="Collaborator"
				data-testid="dashboard-access-level-collaborator" />
		</div>
	);
}